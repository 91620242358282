@import '../../../bower_components/k-sass-core/src/sass/k-sass-core';

@import "_include/body";
@import "_include/_header/header001";
@import "_include/_footer/footer001";
@import "_include/_rotate/rotate";
/////////////////////////////////
.home {
    .header {
        .banner {
            @include pos(rel,null);
            z-index: 200;
            overflow: hidden;
            img {
                @include pos(abs,0 null 0 50%);
                z-index: 100;
                max-width: unset;
                min-width: 100%;
                height: 100%;
                object-fit: cover;
                @include transform(translate(-50%)); } } }
    .title-group {
        font-family: Tw-Cen-MT-Bold;
        @include fs(43rem);
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
        padding: 15px 0;
        /////1760 x 846 - 92%
        @media(max-width:1760px) {
            @include fs(39.56rem); }
        /////1600 x 769 - 83%
        @media(max-width:1600px) {
            @include fs(35.69rem); }
        /////1440 x 692 - 75%
        @media(max-width:1440px) {
            @include fs(32.25rem); }
        /////1366 x 656 - 72%
        @media(max-width:1366px) {
            @include fs(30.96rem); }
        /////1280 x 615 - 67%
        @media(max-width:1280px) {
            @include fs(28.81rem); }
        /////51%
        @include media-breakpoint-down(lg) {
            @include fs(21.93rem); }
        /////28%
        @include media-breakpoint-down(sm) {
 }            //+fs(18rem)
        /////17%
        @include media-breakpoint-down(xs); }
    .group-about {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
        /////1760 x 846 - 92%
        @media(max-width:1760px) {
            padding: calc(40px * 0.92) 0; }
        /////1600 x 769 - 83%
        @media(max-width:1600px) {
            padding: calc(40px * 0.83) 0; }
        /////1440 x 692 - 75%
        @media(max-width:1440px) {
            padding: calc(40px * 0.75) 0; }
        /////1366 x 656 - 72%
        @media(max-width:1366px) {
            padding: calc(40px * 0.72) 0; }
        /////1280 x 615 - 67%
        @media(max-width:1280px) {
            padding: calc(40px * 0.67) 0; }
        /////51%
        @include media-breakpoint-down(lg) {
            padding: calc(40px * 0.51) 0; }
        /////28%
        @include media-breakpoint-down(sm) {
            padding: calc(40px * 0.28) 0;
            flex-wrap: wrap; }
        /////17%
        @include media-breakpoint-down(xs) {
            padding: 0 0 calc(40px * 0.17) 0; }
        .ct-about {
            @include fs(20rem);
            font-family: 'AkhandSoft-Light';
            padding: 0 20px 0 60px;
            /////1760 x 846 - 92%
            @media(max-width:1760px) {
                @include fs(18.4rem);
                padding: 0 calc(20px * 0.92) 0 calc(60px * 0.92); }
            /////1600 x 769 - 83%
            @media(max-width:1600px) {
                @include fs(16.6rem);
                padding: 0 calc(20px * 0.83) 0 calc(60px * 0.83); }
            /////1440 x 692 - 75%
            @media(max-width:1440px) {
                @include fs(15rem);
                padding: 0 calc(20px * 0.75) 0 calc(60px * 0.75); }
            /////1366 x 656 - 72%
            @media(max-width:1366px) {
                padding: 0 calc(20px * 0.72) 0 calc(60px * 0.72); }
            /////1280 x 615 - 67%
            @media(max-width:1280px) {
                padding: 0 calc(20px * 0.67) 0 calc(60px * 0.67); }
            /////51%
            @include media-breakpoint-down(lg) {
                padding: 0 calc(20px * 0.51) 0 calc(60px * 0.51); }
            /////28%
            @include media-breakpoint-down(sm) {
                padding-left: calc(20px * 0.51);
                text-align: center; }
            /////17%
            @include media-breakpoint-down(xs);
            span {
                font-family: 'AkhandSoft-Extrabold'; } }
        .global,.forlife {
            padding-left: 30px;
            /////51%
            @include media-breakpoint-down(lg) {
                width: 10%;
                text-align: center; }
            /////40%
            @include media-breakpoint-down(md) {
                width: 15%; }
            /////28%
            @include media-breakpoint-down(sm) {
                width: 50%;
                img {
                    width: 70%; } }
            /////17%
            @include media-breakpoint-down(xs) {
                padding-top: 10px;
                padding-bottom: 10px;
                img {
                    width: 50%; } } }
        .forlife {}
        img {} }
    .group-products {
        .title-group {
            background-color: #efc9af;
            margin-bottom: 50px;
            /////1760 x 846 - 92%
            @media(max-width:1760px) {
                margin-bottom: calc(50px * 0.92); }
            /////1600 x 769 - 83%
            @media(max-width:1600px) {
                margin-bottom: calc(50px * 0.83); }
            /////1440 x 692 - 75%
            @media(max-width:1440px) {
                margin-bottom: calc(50px * 0.75); }
            /////1366 x 656 - 72%
            @media(max-width:1366px) {
                margin-bottom: calc(50px * 0.72); }
            /////1280 x 615 - 67%
            @media(max-width:1280px) {
                margin-bottom: calc(50px * 0.67); }
            /////51%
            @include media-breakpoint-down(lg) {
                margin-bottom: calc(50px * 0.51); }
            /////28%
            @include media-breakpoint-down(sm) {
                margin-bottom: calc(50px * 0.28); }
            /////17%
            @include media-breakpoint-down(xs) {
                margin-bottom: calc(50px * 0.17); } }
        .products {
            display: flex;
            flex-wrap: no-wrap;
            justify-content: center;
            @include media-breakpoint-down(xs) {
                flex-wrap: wrap; }
            .item {
                float: left;
                width: calc(100% / 4);
                padding: 0 32px;
                @include transition(all .3s ease);
                /////1760 x 846 - 92%
                @media(max-width:1760px) {
                    padding: 0 calc(32px * 0.92); }
                /////1600 x 769 - 83%
                @media(max-width:1600px) {
                    padding: 0 calc(32px * 0.83); }
                /////1440 x 692 - 75%
                @media(max-width:1440px) {
                    padding: 0 calc(32px * 0.75); }
                /////1366 x 656 - 72%
                @media(max-width:1366px) {
                    padding: 0 calc(32px * 0.72); }
                /////1280 x 615 - 67%
                @media(max-width:1280px) {
                    padding: 0 calc(32px * 0.67); }
                /////51%
                @include media-breakpoint-down(lg);
                /////28%
                @include media-breakpoint-down(sm) {
                    width: calc(100% / 3);
                    padding: 0 calc(32px * 0.28); }
                /////17%
                @include media-breakpoint-down(xs) {
                    width: calc(100% / 2.9);
                    padding: 10px 15px; }
                &:hover {
                    @include transform(translate3d(0,-10px,0)); }
                a {
                    display: block; }
                img {
                    width: 100%; } } } }
    .group-info {
        text-align: center;
        margin-top: 25px;
        /////1760 x 846 - 92%
        @media(max-width:1760px) {
            img {
                width: 92%; } }
        /////1600 x 769 - 83%
        @media(max-width:1600px) {
            img {
                width: 83%; } }
        /////1440 x 692 - 75%
        @media(max-width:1440px) {
            img {
                width: 75%; } }
        /////1366 x 656 - 72%
        @media(max-width:1366px) {
            img {
                width: 67%; } }
        /////1280 x 615 - 67%
        @media(max-width:1280px) {}
        /////51%
        @include media-breakpoint-down(lg) {
            img {
                width: 57%; } }
        /////28%
        @include media-breakpoint-down(sm) {
            img {
                width: 47%; } }
        /////17%
        @include media-breakpoint-down(xs);
        a {
            display: inline-block; }
        img {
            @include transition(all .3s ease);
            &:hover {
                @include transform(scale(1.01)); } } }
    .group-contact {
        .title-group {
            padding-bottom: 35px;
            @include pos(rel,null);
            &:after {
                content: '';
                width: 272px;
                height: 3px;
                background-color: $main;
                @include pos(abs,null null 0 50%);
                @include transform(translate(-50%)); } }
        .contact {
            text-align: center;
            @include fs(24rem);
            font-family: AkhandSoft-Light;
            padding: 15px;
            /////1760 x 846 - 92%
            @media(max-width:1760px) {
                @include fs(22.04rem); }
            /////1600 x 769 - 83%
            @media(max-width:1600px) {
                @include fs(19.92rem); }
            /////1440 x 692 - 75%
            @media(max-width:1440px) {
                @include fs(18rem); }
            /////1366 x 656 - 72%
            @media(max-width:1366px) {
                @include fs(17.28rem); }
            /////1280 x 615 - 67%
            @media(max-width:1280px) {
                @include fs(16.08rem); }
            /////51%
            @include media-breakpoint-down(lg);
            /////28%
            @include media-breakpoint-down(sm);
            /////17%
            @include media-breakpoint-down(xs) {
                padding-bottom: 0; }
            .pg {
                span {
                    font-family: AkhandSoft-Semibold;
                    @include fs(36rem);
                    /////1760 x 846 - 92%
                    @media(max-width:1760px) {
                        @include fs(33.12rem); }
                    /////1600 x 769 - 83%
                    @media(max-width:1600px) {
                        @include fs(29.88rem); }
                    /////1440 x 692 - 75%
                    @media(max-width:1440px) {
                        @include fs(27rem); }
                    /////1366 x 656 - 72%
                    @media(max-width:1366px) {
                        @include fs(25.92rem); }
                    /////1280 x 615 - 67%
                    @media(max-width:1280px) {
                        @include fs(24.12rem); }
                    /////51%
                    @include media-breakpoint-down(lg);
                    /////28%
                    @include media-breakpoint-down(sm);
                    /////17%
                    @include media-breakpoint-down(xs); } }
            .website {
                padding: 70px 0 35px; } } }
    .group-map {
        background-color: #efc9af;
        .map {
            padding: 40px 10px;
            // /////1760 x 846 - 92%
            // @media(max-width:1760px)
            //     padding: calc(70px * 0.92) 0
            // /////1600 x 769 - 83%
            // @media(max-width:1600px)
            //     padding: calc(70px * 0.83) 0
            // /////1440 x 692 - 75%
            // @media(max-width:1440px)
            //     padding: calc(70px * 0.75) 0
            // /////1366 x 656 - 72%
            // @media(max-width:1366px)
            //     padding: calc(70px * 0.72) 0
            // /////1280 x 615 - 67%
            // @media(max-width:1280px)
            //     padding: calc(70px * 0.67) 0
            // /////51%
            // +media-breakpoint-down(lg)
            //     padding: calc(70px * 0.51) 10px
            // /////28%
            // +media-breakpoint-down(sm)
            // /////17%
            // +media-breakpoint-down(xs)
            iframe {
                height: 100%; } } } }
